import React from 'react';
import { Headings } from '../../components';
import { data } from '../../constants';
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';

import './Services.css';


const Services = () => {
  return (
    <div id="appfeatures" className="d-block pt-md-4">
      <Headings title="Exquisite" text="Chapcorner unique and very decorative app feautures" />

      <div className="row">
        {data.ServicesData.map(({ titleone, titletwo, link, itemclass, imgURL, paragraph }, index) => (
          <div className="col-lg-6 col-12" key={index}>
            <div className={`row ${itemclass}`}>
              <div className="col-md-6 box">
                <div>  <span>{titleone} </span>
                  {/* <span>{titletwo}</span> */}
                  <p style={{ marginTop: 40 }}>{paragraph}</p>
                </div>
                {/* <a href={link} alt={titleone} className="readmore"> <BsFillArrowUpRightCircleFill /> Learn more </a> */}
              </div>
              <div className="col-md-6 text-end"><img src={imgURL} alt={titleone} className="img-fluid img-services" /></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services