import images from './images';

const Menu = [
    {
        text: 'About us',
        link: '#team',
    },
    // {
    //     text: 'Solution',
    //     link: '#services',
    // },
    {
        text: 'Cases studies',
        link: '#use-cases',
    },
    {
        text: 'Our Solutions',
        link: '#solution',
    },

    {
        text: 'Our Team',
        link: '#team',
    }
];


const ServicesData = [
    {
        titleone: 'Stock List',
        titletwo: 'optimization',
        link: '#',
        itemclass: 'blight',
        imgURL: images.services01,
        paragraph: 'Modernize your inventory management with a digital solution.'
    },
    {
        titleone: 'Orders',
        titletwo: 'advertising',
        link: '#',
        itemclass: 'bgreen',
        imgURL: images.services02,
        paragraph: 'Refer to your past purchases to effectively organize your inventory.'
    },
    {
        titleone: 'Easy Payment',
        titletwo: 'Marketing',
        link: '#',
        itemclass: 'bdark',
        imgURL: images.services03,
        paragraph: 'Pay instantly and securely using Mangi Wallet or Pesapal during checkout.'
    },
    {
        titleone: 'Reorder',
        titletwo: 'Marketing',
        link: '#',
        itemclass: 'blight',
        imgURL: images.services04,
        paragraph: 'Hustle free reordering of items from your previous orders.'
    },
    {
        titleone: 'Categories',
        titletwo: 'Creation',
        link: '#',
        itemclass: 'bgreen',
        imgURL: images.services05,
        paragraph: 'Explore various categories to effortlessly locate the items you need.'
    },
    {
        titleone: 'Delivery',
        titletwo: 'Tracking',
        link: '#',
        itemclass: 'bdark',
        imgURL: images.services06,
        paragraph: 'We provide fast and reliable deliveries to your location.'
    },
]
const CaseStudies = [
    {
        name: 'Shayo\'s Apparel Emporium',
        text: 'Struggling with unstable prices and unreliable suppliers, Shayo found stability and transparency with Chapcorner. By connecting her to trustworthy suppliers and offering transparent transactions, Chapcorner helped Shayo boost sales by 30%, securing his position in the market.',
        link: '#',
    },
    {
        name: 'Sarah\'s Specialty Foods',
        text: 'Sarah\'s struggles with sourcing quality ingredients at affordable prices were alleviated by Chapcorner. Access to trusted suppliers and transparent pricing led to a 20% increase in profit margins, solidifying her store\'s reputation and paving the way for sustained success.',
        link: '#',
    },
    {
        name: 'Mulokozi Electronics',
        text: 'Mulokozi Electronics faced supply chain challenges causing delays and inconsistencies. With Chapcorner\'s integration, they streamlined operations, reducing costs by 25% and improving delivery times by 40%, enhancing customer satisfaction and market competitiveness.',
        link: '#',
    },
];

const WorkingProcess = [
    {
        title: 'Supply Chain Integration',
        description: 'Chapcorner offers a digital commerce platform that integrates the supply chain for Tanzanian retailers. This includes connecting retailers directly with suppliers, distributors, and manufacturers, streamlining the procurement process, and reducing inefficiencies in the supply chain.',
    },
    {
        title: 'Price Transparency',
        description: 'Chapcorner ensures pricing transparency by providing retailers with access to manufacturer-suggested prices. This transparency enables retailers to make informed purchasing decisions, negotiate fair deals with suppliers, and maintain competitive pricing for their customers.',
    },
    {
        title: 'Digital Payments',
        description: 'Chapcorner facilitates digital payments through its platform, offering Tanzanian retailers secure and flexible payment options. This eliminates the need for cash transactions, reduces the risk of theft or fraud, and enhances financial transparency for retailers.',
    },
    {
        title: 'Real-Time Commnunication',
        description: 'Chapcorner enables real-time communication between retailers and suppliers, allowing for seamless collaboration and faster response times. This ensures that retailers can quickly address inventory needs, place orders, and track shipments, improving overall efficiency in the supply chain.',
    },
    {
        title: 'Inventory Management',
        description: 'Chapcorner provides tools for inventory management, allowing Tanzanian retailers to track stock levels, monitor product demand, and optimize inventory turnover. This helps retailers minimize stockouts, reduce excess inventory costs, and maximize sales opportunities.',
    },
    {
        title: 'Market Access',
        description: 'Chapcorner expands market access for Tanzanian retailers by connecting them with a broader network of suppliers and manufacturers. This enables retailers to access a wider range of products, including both local and imported goods, catering to diverse customer preferences.',
    },
    {
        title: 'Compliance and Regulation',
        description: 'Chapcorner helps Tanzanian retailers navigate regulatory requirements and compliance standards, ensuring adherence to local laws and regulations governing retail operations. This reduces the risk of regulatory violations and associated penalties, safeguarding retailers\' businesses.'
    }

];

const Team = [
    {
        name: 'Jeanauguste Desire',
        position: 'The Visionary',
        info: 'Meet our visionary Architect. With a blend of entrepreneurial acumen and tech industry expertise, he leads Chapcorner with unwavering determination and innovative thinking. With a background in Software Engineering, he brings a wealth of experience to the table, driving the company\'s mission to re-engineer retail supply chains. His relentless pursuit of excellence have propelled Chapcorner to new heights, positioning the company in the digital commerce landscape.',
        foto: images.team01,
        linkedin: '#',
    },
    {
        name: 'Jocelyne Kishan',
        position: 'The Controller',
        info: 'Meet our Finance Director, Miss. Jocelyne Kishan. With a stellar track record in financial management and strategic planning, she brings a wealth of expertise to Chapcorner. With a background in financial services and supply chain management, she plays a pivotal role in guiding the company\'s financial strategy and ensuring fiscal responsibility. Her keen financial insights and meticulous attention to detail are instrumental in driving Chapcorner\'s growth and success.',
        foto: images.team02,
        linkedin: '#',
    },
    {
        name: 'Churchill Shakim',
        position: 'The Fixer',
        info: 'Meet our Operations Director, Mr. Churchill Shakim. With a wealth of experience in operational excellence and strategic management, he plays a pivotal role in ensuring the smooth and efficient functioning of Chapcorner\'s day-to-day operations. Churchill\'s leadership skills, combined with meticulous attention to detail, enable him to oversee key aspects of the company\'s supply chain, logistics, and customer service operations.',
        foto: images.team03,
        linkedin: '#',
    },
    {
        name: 'Eliah Mwakijale',
        position: 'The Growth Lead',
        info: 'Meet our Growth Lead, the driving force behind Chapcorner\'s expansion and market penetration. With a keen understanding of market dynamics and a strategic mindset, he spearheads initiatives to propel Chapcorner\'s growth trajectory. Leveraging extensive experience in business development and customer acquisition, he identifies untapped opportunities, develops innovative strategies, and fosters strategic partnerships to drive revenue and market share',
        foto: images.team04,
        linkedin: '#',
    },
    {
        name: 'Julianeth Bashange',
        position: 'The Human Capital Lead',
        info: 'Meet our Human Resource Manager. With a passion for fostering a vibrant and inclusive workplace culture, She is dedicated to nurturing talent and empowering our team at Chapcorner. Armed with extensive experience in human resources and organizational development, she brings a strategic approach to talent management and employee engagement. She is committed to creating an environment where every team member can thrive, innovate, and contribute to our collective success.',
        foto: images.team05,
        linkedin: '#',
    },
    {
        name: 'Cretus Damiani',
        position: 'Strategic Alliances Personel',
        info: 'Meet our esteemed Business Relations Officer, Mr. Cretus Damian. With a profound understanding of the intricate dynamics of business relations, he leads Chapcorner\'s strategic partnerships with finesse and expertise. Armed with a background in Business Development and Coorporate Partnerships, he brings a wealth of experience in fostering mutually beneficial relationships and driving business growth. He is dedicated to expanding Chapcorner\'s network that propel the company\'s mission forward.',
        foto: images.team06,
        linkedin: '#',
    },

];

const Testimonials = [
    {
        name: 'David Kimario',
        position: 'Retailer @ Mbezi Mwisho',
        testimonial: '"Chapcorner has revolutionized how we manage our supply chain! With direct access to suppliers at transparent prices, our procurement process has never been smoother. Highly recommended!"',
    },
    {
        name: 'Mwajuma Abdallah',
        position: 'Retailer @ Saranga',
        testimonial: '"Chapcorner\'s Mangi Wallet is a game-changer for retailers like us! Being able to deposit cash and pay directly on the platform, all while earning loyalty points for every transaction, is simply fantastic. It\'s like getting rewarded for doing business! We love how these points are reusable for future purchases, making our shopping experience even more rewarding"',
    },
    {
        name: 'Baba Caren',
        position: 'Retailer @ Goba Center',
        testimonial: '"Chapcorner\'s \'Buy Now, Pay Later\' program has been a lifesaver for us during tight spots. When we urgently needed stock but were short on cash, Chapcirner provided the flexibility to make purchases without immediate payment. It\'s a game-changer for managing our inventory and keeping our shelves stocked, even when funds are tight"',
    },
    {
        name: 'Fadhil Kiyemba',
        position: 'Retailer @ Ubungo',
        testimonial: '"Chapcorner has transformed the way we do business. From real-time communication with suppliers to inventory management tools, it\'s everything we need in one platform!"',
    },
    {
        name: 'Kishimba Junior',
        position: 'Retailer @ Kinyerezi',
        testimonial: '"Chapcorner\'s multiple digital payment options have simplified our financial operations and increased our efficiency. We couldn\'t be happier with the seamless experience!"',
    },
];
export default { Menu, CaseStudies, WorkingProcess, Team, Testimonials, ServicesData };