import React from 'react';
import './CTA.css';
import { images } from '../../constants';
import chapcornerpitchdeck from '../Uploads/chapcornerpitchdeck.pdf';

const CTA = () => {
  return (
    <div className="section-padding">
      <div className="row cta">
        <div className="col-lg-8 col-12">
          <h3>Let’s shape the future of retail together</h3>
          <p className="pt-2 pb-4">Your expertise and insights could be invaluable in propelling Chapcorner to new heights. I invite you to dive into our pitch deck and explore the possibilities together.</p>
          <a href={chapcornerpitchdeck} style={{ textDecoration: 'none' }}>
            <button className="btn-chapcorner">See our pitch deck</button>
          </a>
        </div>
        <div className="col-lg-4 d-lg-flex d-none" style={{ alignItems: 'center', marginTop: 15 }}>
          <img src={images.appfeatures} alt="thingshappen" />
        </div>
      </div>
    </div>
  );
};

export default CTA;
