import React from 'react';
import { images } from '../../constants';
import { IconScroll } from '../../components';
import './Hero.css';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';


const logos = ["asas", "azamlogo", "azanialogo", "hill", "oryx", "taifagas"];

const Hero = () => {

  const [text] = useTypewriter({
    words: ["the future of digital commerce", "the bridge to seamless transactions", "the foundation for credit building"],
    loop: 0,
    typeSpeed: 100,
    deleteSpeed: 80,
    delaySpeed: 1000
  })

  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <div className="title-container" style={{ height: "120px" }}>
            <h1 className="title"> We're building
              <span style={{ color: "#f9aa33" }}> {text}</span>
              <span style={{ color: "#f9aa33" }}>
                <Cursor cursorStyle='|' />
              </span>
              for retail supply chains
            </h1>
          </div>
          <br />
          <p className="py-4">Chapcorner is an innovative digital commerce solution designed to address
            supply chain challenges faced by small and medium-sized retail establishments.
            Its primary goal is to provide these businesses with a dependable and seamless
            connection to suppliers, ensuring access to products and the manufacturers’
            recommended prices.</p>

          <button className="btn-chapcorner" style={{ fontSize: 16, fontWeight: 'bold' }}><FontAwesomeIcon icon={faGooglePlay} size="1" style={{ paddingRight: 4 }} />Playstore</button><span style={{ paddingRight: 5 }} />
          <button className="btn-chapcorner" style={{ fontSize: 16, fontWeight: 'bold' }}><FontAwesomeIcon icon={faApple} size="1" style={{ paddingRight: 4 }} />Appstore</button>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={images.homeui} alt="design" />
        </div>
      </div>

      <div className="clients">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={images[logo]}
            alt={images[logo]}
          />
        ))}

      </div>
      <IconScroll />
    </div>

  )
}

export default Hero