import React from 'react'
import { Headings, IconScroll } from '../../components';
import { data } from '../../constants';
import './CaseStudies.css';
import { FiArrowUpRight } from 'react-icons/fi'


const CaseStudies = () => {
  return (
    <div className="section-padding" id="use-cases">

      <Headings title="Case Studies" text="Discover How Chapcorner Transformed Businesses: Real-Life Success Stories Unveiled" />

      <div className="case-studies">

        {data.CaseStudies.map(({ name, text, link }, index) => (
          <div key="index" className="case-studies-item">
            <h1 style={{ fontSize: 22, fontWeight: 'bold', paddingLeft: 18 }}>{name}</h1>
            <p style={{ marginBottom: -5 }}>{text}</p>
            {/* <a href={link} alt="Learn more">Learn more <FiArrowUpRight /></a> */}
          </div>
        ))}
      </div>

      <IconScroll />

    </div>

  )
}

export default CaseStudies