import React from 'react';
import { data, images } from '../../constants';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Menu.css';


const Menu = () => {
    return (

        <Navbar collapseOnSelect expand="lg" bg="ligth" variant="ligth">
            <Container>
                <Navbar.Brand href="#home"> <img src={images.chapcornerlogo} alt="logo" style={{ width: 110, height: 80 }} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav style={{ flex: 1, justifyContent: 'center' }}>
                        {data.Menu.map((item, index) => (
                            <Nav.Link href={item.link}>{item.text}</Nav.Link>
                        ))}
                    </Nav>
                    <Nav>
                        <Nav.Link href='https://shop.chapcorner.com' style={{ background: '#f9aa33', border: 1, borderRadius: 5 }}>Shop Now</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>


    )
}

export default Menu